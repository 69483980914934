import { PropsWithChildren } from "react";
import styled from "styled-components";

type Props = PropsWithChildren<unknown> & {
  title?: string,
  description: JSX.Element,
  mediaSide?: string,
}

const Title = styled.h1`
  color: white;
  font-size: 24px;
  margin: 0px 0px 10px 0px;
`;

const Description = styled.p`
  color: white;
  font-size: 20px;
  margin: 0px;
`;

const Container = styled.div`
  @media not screen and (max-width: 1099px) {
    display: flex;
    gap: 15px;
    flex-direction: column;

    &.left.half {
      flex-direction: row-reverse;
    }

    &.right.half {
      flex-direction: row;
    }

    &.half>* {
      width: 50%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1099px) {
    display: flex;
    gap: 15px;
    flex-direction: column;
  }
`;

function MediaAndDescription(props: Props) : JSX.Element {
  if (props.mediaSide !== "left" && props.mediaSide !== "right" && props.mediaSide !== undefined) {
    throw "Unknown floatSide " + props.mediaSide;
  }

  return <Container className={props.mediaSide + " " + (props.children !== undefined ? "half" : "full")}>
    <div>
      {props.title && <Title>{props.title}</Title>}
      <Description>{props.description}</Description>
    </div>
    {props.children && (<div>
      {props.children}
    </div>)}
  </Container>;
}

export default MediaAndDescription;