import { useEffect, useState } from "react";

type Props = {
  src: string;
}

function CubeIFrame(props: Props) : JSX.Element {
  const defaultHeightF = 720;
  const defaultHeight = defaultHeightF + "px";
  const [height, setHeight] = useState(defaultHeight)

  useEffect(() => {
    function ResizeCubeWindow()
    {
      const cubeFrame = document.getElementById("cube")!;
      const style = window.getComputedStyle(cubeFrame);
      const width = style.getPropertyValue("width");

      let newHeightF = parseFloat(width.replace("px", ""));
      if (newHeightF > 720) {
        newHeightF = defaultHeightF;
      }

      if (newHeightF > window.innerHeight - 100) {
        newHeightF = window.innerHeight - 100;
      }

      setHeight(newHeightF + "px");
    }

    window.addEventListener("resize", ResizeCubeWindow);
    ResizeCubeWindow();
    return () => window.removeEventListener("resize", ResizeCubeWindow);
  }, []);

  return <iframe src={props.src} width="100%" height={height} id="cube" frameBorder="1"></iframe>;
}

export default CubeIFrame;